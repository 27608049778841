import React, { memo } from "react";
import {
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  Box,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import TablePaginator from "../TablePaginator";
import TableCell from "./TableCell";
import NotFoundMessage from "./NotFoundMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  notFound: {
    // a fixed height is required to display a huge middle cell with a not found message
    height: 300,
  },
  table: {
    tableLayout: "fixed",
    height: "100%",
  },
  paginator: {
    paddingRight: theme.spacing(2),
  },
}));

function DesktopTable({
  columns,
  data,
  filters,
  totalCount,
  orderBy,
  order,
  onSortChange,
  itemsPerPage,
  page,
  onPageChange,
  onItemsPerPageChange,
  children,
  className,
  notFound,
  notFoundMessage,
  loading,
  columnsLoading,
  tableRef,
  testId,
  containerStyles,
  hidePagination=false,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const widthSum = columns.reduce(
    (sum, column) => sum + (column.width ?? 1),
    0
  );

  return (
    <>
      {filters && <Box mb={2}>{filters}</Box>}
      <TableContainer
        component={Paper}
        className={classNames(classes.root, {
          [classes.notFound]: notFound,
        })}
        style={containerStyles}
        ref={tableRef}
      >
        <Table
          {...rest}
          className={classNames(className, classes.table)}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  align={column.align}
                  sortable={column.sortable}
                  key={column.name || idx}
                  name={column.name}
                  onSortChange={onSortChange}
                  orderBy={orderBy}
                  order={order}
                  noWrap={column.noWrap}
                  width={(column.width || 1) / widthSum}
                  testId={column.testId}
                >
                  {columnsLoading ? <Skeleton height={30} /> : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {notFound ? (
              <NotFoundMessage columns={columns} message={notFoundMessage} />
            ) : (
              data.map((row, idx) => (
                <TableRow key={row?._id || idx}>
                  {columns.map((column, idx) => (
                    <TableCell
                      data-test-id={
                        column.name &&
                        `${testId}-${column.name}${row?._id && `-${row._id}`}`
                      }
                      key={column.name || idx}
                      align={column.align}
                    >
                      {loading ? (
                        <Skeleton height={30} />
                      ) : column.getter ? (
                        column.getter(row)
                      ) : (
                        row[column.name]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
          {!hidePagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={columns.length}
                  className={classes.paginator}
                  count={totalCount}
                  page={page || 0}
                  rowsPerPage={itemsPerPage || 20}
                  rowsPerPageOptions={[20, 35, 50]}
                  labelRowsPerPage={`${t("Max rows per page")}:`}
                  SelectProps={{
                    inputProps: { "aria-label": t("Max rows per page") },
                  }}
                  onChangePage={onPageChange}
                  onChangeRowsPerPage={onItemsPerPageChange}
                  ActionsComponent={TablePaginator}
                  data-test-id={`${testId}-pagination`}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default memo(DesktopTable);
