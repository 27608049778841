import React, { useCallback, useState, useRef, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  getMasterTemplate,
  saveMasterTemplate,
  clearMasterTemplate,
  deleteMasterTemplate,
  cloneMasterTemplate,
} from "../../../actions/masterTemplate";
import useAuthorized, {
  TEMPLATE_UPDATE_PERMISSION,
} from "../../../hooks/useAuthorized";
import { Page, Box, DeleteDialog } from "../../../components";
import BaseMasterTemplatePage from "./Base";
import Controls from "./Controls";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    overflow: "auto",
  },
}));

function CreateEditViewMasterTemplatePage({ isEditingView = false }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { masterTemplateId } = useParams();
  const history = useHistory();
  const isNavToUpdate = useRef(false);
  const currentUrlRef = useRef(window.location.pathname);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [counts, setCounts] = useState(0);
  const masterTemplate = useSelector((state) => state.masterTemplate ?? {});
  const user = useSelector((state) => state.user);
  const { name, errors = {}, userId, status, _id } = masterTemplate;
  const hasErrors = 0 < Object.keys(errors).length;

  const hasEditPermission = useAuthorized(TEMPLATE_UPDATE_PERMISSION);

  const isNewTemplate = !_id;
  const canEdit =
    useSelector((state) => state.user.id === userId) || hasEditPermission;
  const isTemplateLocked = status === "LOCKED";

  const onDeleteToggle = useCallback(
    () => setShowDeleteDialog(!showDeleteDialog),
    [setShowDeleteDialog, showDeleteDialog]
  );

  useEffect(() => {
    if (!isEditingView) {
      dispatch(clearMasterTemplate());
    }
  }, [dispatch, isEditingView]);

  useEffect(() => {
    setCounts(counts + 1);
    const tempReadOnly = !isNewTemplate && !(canEdit && !isTemplateLocked);

    if (isEditingView) {
      if (counts == 1) {
        setReadOnly(tempReadOnly);
      }
    } else {
      setReadOnly(false);
    }
  }, [isNewTemplate, canEdit, isTemplateLocked]);

  const saveWithStatus = useCallback(
    (status, initial) => {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const data = await dispatch(saveMasterTemplate({ status, initial }));
          if (!masterTemplateId && data) {
            isNavToUpdate.current = true;
            history.replace(`/master-templates/${data.masterTemplateId}`);
          }
          resolve(data);
        }, 200);
      });
    },
    [dispatch, history, masterTemplateId, isNavToUpdate]
  );
  useEffect(() => {
    // Listen for history changes to set isNavToUpdate to true if going from new to update page and to false otherwise
    const unlisten = history.listen((location, action) => {
      const isCreatePage = currentUrlRef.current.includes(
        "/master-templates/create"
      );
      const isUpdatePage =
        location.pathname.includes("/master-templates/") &&
        !location.pathname.includes("/master-templates/create");

      if (action === "REPLACE" && isCreatePage && isUpdatePage) {
        isNavToUpdate.current = true;
      } else {
        isNavToUpdate.current = false;
      }

      currentUrlRef.current = location.pathname;
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const onSave = useCallback(() => saveWithStatus("COMPLETED"), [
    saveWithStatus,
  ]);
  const onSaveAsDraft = useCallback(() => saveWithStatus("DRAFT"), [
    saveWithStatus,
  ]);

  const onSaveAsDraftInitial = useCallback(async (initial = true) => {
    if (!masterTemplateId) {
      await saveWithStatus("DRAFT", initial);
    }
  }, [masterTemplateId, saveWithStatus]);

  const onDelete = useCallback(async () => {
    await dispatch(deleteMasterTemplate(masterTemplate));
    history.replace("/master-templates");
  }, [dispatch, history, masterTemplate]);

  useEffect(() => {
    dispatch(getMasterTemplate(masterTemplateId));

    // initialized template as Draft
    // if (!masterTemplateId) onSaveAsDraft();
    return () => {
      // keep the master template in memory if we are going to the update page from the create page
      if (!isNavToUpdate.current) {
        dispatch(clearMasterTemplate());
      }
    };
  }, [dispatch, masterTemplateId, isNavToUpdate, onSaveAsDraft]);

  const onClone = useCallback(async () => {
    const cloneId = await dispatch(
      cloneMasterTemplate({ _id: masterTemplateId, name: "test" })
    );
    history.push(`/master-templates/${cloneId}`);
  }, [dispatch, history, masterTemplateId]);

  return (
    <Page screenSized data-test-id="single-master-template-page">
      <Box p={2} className={classes.content}>
        <BaseMasterTemplatePage
          masterTemplate={masterTemplate}
          readOnly={readOnly}
          onSaveAsDraftInitial={onSaveAsDraftInitial}
          isEditingView={isEditingView}
        />
      </Box>
      {!readOnly && (
        <Controls
          onSave={onSave}
          onSaveAsDraft={onSaveAsDraft}
          onClone={onClone}
          onDelete={onDeleteToggle}
          hasErrors={hasErrors}
          isNewTemplate={isNewTemplate}
        />
      )}
      <DeleteDialog
        data-test-id="delete-master-template-dialog"
        open={showDeleteDialog}
        onClose={onDeleteToggle}
        onDelete={onDelete}
        name={name}
        resource={t("Template")}
      />
    </Page>
  );
}

export default memo(CreateEditViewMasterTemplatePage);
