import React, {
  useCallback,
  useState,
  memo,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { omit, omitBy } from "lodash";
import { Grid, Alert, Tooltip } from "../../../../components";
import {
  Add,
  AttachFile,
  Info as InfoIcon,
  Directions,
  Dialpad,
} from "../../../../icons";
import {
  buildMasterTemplate,
  getAudioForTTs,
} from "../../../../actions/masterTemplate";
import { uploadWavFile } from "../../../../actions/masterTemplate";
import {
  FILE_TYPE,
  CUSTOMER_DATA_TYPE,
  TTS_DATA_TYPE,
  LIVE_TYPE,
  MACHINE_TYPE,
  SIMPLE_VOICE_INPUT,
  ADVANCE_VOICE_INPUT,
  DTMF_DATA_TYPE,
  DIRECTION_DATA_TYPE,
} from "../constants";
import VoiceItems from "./VoiceItems";
import VoiceTTS from "./VoiceTTS";
import VoiceFields from "./VoiceFields";
import VoiceTypeToggle from "./VoiceTypeToggle";
import VoiceInputToggle from "./VoiceInputToggle";
import VoiceAdvanceField from "./VoiceAdvanceFields";
import _ from "lodash";
import VoiceStepButtons from "./VoiceStepButtons";

const useStyles = makeStyles((theme) => ({
  fileSelector: {
    width: "100%",
    height: 120,
  },
  flexDivider: {
    marginLeft: "auto",
  },
  padded: {
    marginRight: "38px !important",
    marginLeft: "38px !important",
  },
  dragIndicator: {
    cursor: "grab",
  },
}));

const VoiceTemplateSection = forwardRef(
  (
    {
      voiceTemplate = {
        voiceType: "freeswitch",
        freeswitch: {
          dialPlan: {
            person: {
              steps: [],
            },
          },
        },
      },
      voiceConfig,
      eonsConfig,
      onVoiceFieldChange,
      errors = {},
      readOnly,
      adhoc,
      isEditingView,
      onSaveAsDraftInitial,
      callerIdDetail,
    },
    ref
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { advanceVoice, _id: templateId } = voiceTemplate;
    const [activeTab, setActiveTab] = useState(LIVE_TYPE);
    const [advanceTab, setAdvanceTab] = useState(SIMPLE_VOICE_INPUT);
    const [copyLive, setCopyLive] = useState(false);
    const isFirstRender = useRef(true);

    if (!voiceTemplate.freeswitch) {
      voiceTemplate["freeswitch"] = {
        dialPlan: {
          machine: null,
          person: {
            steps: [],
          },
        },
      };
      voiceTemplate["voiceType"] = "freeswitch";
    }
    const {
      freeswitch: { dialPlan },
    } = voiceTemplate;

    const { steps: voiceItems } = dialPlan[activeTab]
      ? dialPlan[activeTab]
      : { steps: [] };
    const onToggleAnsweringMachine = useCallback(() => {
      dispatch(
        buildMasterTemplate({
          voiceTemplate: {
            ...voiceTemplate,
            freeswitch: {
              ...voiceTemplate.freeswitch,
              dialPlan: {
                ...voiceTemplate.freeswitch.dialPlan,
                [MACHINE_TYPE]: voiceTemplate.freeswitch.dialPlan[MACHINE_TYPE]
                  ? isEditingView
                    ? voiceTemplate.freeswitch.dialPlan[MACHINE_TYPE]
                    : null
                  : { steps: [] },
              },
            },
          },
        })
      );
      setActiveTab(LIVE_TYPE);
    }, [dispatch, voiceTemplate, setActiveTab]);
    useEffect(() => {
      if (advanceVoice) setAdvanceTab(ADVANCE_VOICE_INPUT);
    }, [advanceVoice]);

    const onSetVoiceItems = useCallback(
      (items, errors) =>
        dispatch(
          buildMasterTemplate({
            voiceTemplate: _.omit(
              {
                ...voiceTemplate,
                freeswitch: {
                  ...voiceTemplate.freeswitch,
                  dialPlan: {
                    ...voiceTemplate.freeswitch.dialPlan,
                    [activeTab]: { steps: items },
                  },
                },
              },
              "advanceVoice"
            ),
            errors,
          })
        ),
      [voiceTemplate, activeTab, dispatch]
    );

    const onAddVoiceItem = useCallback(
      (stepType) => {
        let action = "None";
        if (stepType === "file") {
          action = "playback";
        } else if (stepType === "customerData") {
          action = "playback";
        } else if (stepType === "ttsData") {
          action = "playback";
        } else if (stepType === "dtmf") {
          action = "dtmf";
        } else if (stepType === "goto") {
          action = "goto";
        }
        let steps = [
          ...(voiceTemplate.freeswitch.dialPlan[activeTab].steps || []),
        ];
        steps = [
          ...steps,
          {
            action,
            stepType,
            _id: String(Date.now()),
            name: `${steps.length}`,
          },
        ];

        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                dialPlan: {
                  ...voiceTemplate.freeswitch.dialPlan,
                  [activeTab]: {
                    steps,
                  },
                },
              },
            },
            errors: omit(
              errors,
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps`
            ),
          })
        );
      },
      [voiceTemplate, activeTab, errors, dispatch]
    );

    const onDeleteVoiceItem = useCallback(
      (idx) => {
        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                dialPlan: {
                  ...voiceTemplate.freeswitch.dialPlan,
                  [activeTab]: {
                    steps: voiceTemplate.freeswitch.dialPlan[
                      activeTab
                    ].steps.filter((_, i) => i !== idx),
                  },
                },
              },
            },
            // This will clear voice item erros
            // - to remove only specific item, it will get complex and very algorithmicy
            errors: omitBy(
              errors,
              (_, key) =>
                key.startsWith(
                  `voiceTemplate.freeswitch.dialPlan.${activeTab}.`
                ) !== -1
            ),
          })
        );
      },
      [voiceTemplate, activeTab, errors, dispatch]
    );

    const onAddCustomerData = useCallback(
      () => onAddVoiceItem(CUSTOMER_DATA_TYPE),
      [onAddVoiceItem]
    );

    const onAddTTSData = useCallback(() => onAddVoiceItem(TTS_DATA_TYPE), [
      onAddVoiceItem,
    ]);

    const onAddDirectionData = useCallback(
      () => onAddVoiceItem(DIRECTION_DATA_TYPE),
      [onAddVoiceItem]
    );

    const onAddDTMFData = useCallback(() => onAddVoiceItem(DTMF_DATA_TYPE), [
      onAddVoiceItem,
    ]);

    const onAddVoiceFile = useCallback(() => onAddVoiceItem(FILE_TYPE), [
      onAddVoiceItem,
    ]);

    const onSetCustomerData = useCallback(
      (idx, transcript) => {
        const newItems = [
          ...voiceTemplate.freeswitch.dialPlan[activeTab].steps,
        ];
        newItems[idx] = {
          ...newItems[idx],
          variableName: transcript,
        };
        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                dialPlan: {
                  ...voiceTemplate.freeswitch.dialPlan,
                  [activeTab]: { steps: newItems },
                },
              },
            },
            errors: omit(
              errors,
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableName`,
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.transcript`
            ),
          })
        );
      },
      [voiceTemplate, activeTab, errors, dispatch]
    );
    const setVoiceCustomerData = useCallback(
      (idx, transcript) => {
        const newItems = [
          ...voiceTemplate.freeswitch.dialPlan[activeTab].steps,
        ];
        newItems[idx] = {
          ...newItems[idx],
          variableValue: transcript,
        };
        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                dialPlan: {
                  ...voiceTemplate.freeswitch.dialPlan,
                  [activeTab]: { steps: newItems },
                },
              },
            },
            errors: omit(
              errors,
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableValue`,
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.transcript`
            ),
          })
        );
      },
      [voiceTemplate, activeTab, errors, dispatch]
    );
    const onSetTTSData = useCallback(
      async (idx, transcript) => {
        const newItems = [
          ...voiceTemplate.freeswitch.dialPlan[activeTab].steps,
        ];
        newItems[idx] = {
          ...newItems[idx],
          transcript,
        };
        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                dialPlan: {
                  ...voiceTemplate.freeswitch.dialPlan,
                  [activeTab]: { steps: newItems },
                },
              },
            },
            errors: omit(
              errors,
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.variableName`,
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.transcript`
            ),
          })
        );
      },
      [voiceTemplate, activeTab, errors, dispatch]
    );

    const onSetDirectionData = useCallback(
      (idx, step) => {
        const newItems = [
          ...voiceTemplate.freeswitch.dialPlan[activeTab].steps,
        ];
        newItems[idx] = {
          ...newItems[idx],
          step: step.toString(),
        };
        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                dialPlan: {
                  ...voiceTemplate.freeswitch.dialPlan,
                  [activeTab]: { steps: newItems },
                },
              },
            },
            errors: omit(
              errors,
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.goto`
            ),
          })
        );
      },
      [voiceTemplate, activeTab, errors, dispatch]
    );

    const onSetDTMFData = useCallback(
      (idx, keys) => {
        const newItems = [
          ...voiceTemplate.freeswitch.dialPlan[activeTab].steps,
        ];
        newItems[idx] = { ...newItems[idx], keys };

        // dtmf errors: Filter out general error belong to dtmf in this index
        const errorKeys = Object.keys(errors).filter(
          (e) =>
            !e.startsWith(
              `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}`
            )
        );

        const updatedErrors = {};
        errorKeys.forEach((k) => {
          updatedErrors[k] = errors[k];
        });

        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                dialPlan: {
                  ...voiceTemplate.freeswitch.dialPlan,
                  [activeTab]: { steps: newItems },
                },
              },
            },
            errors: updatedErrors,
          })
        );
      },
      [voiceTemplate, activeTab, dispatch, errors]
    );

    const onDTMFFilePathChange = useCallback(
      (idx, filePath) => {
        const items = [...voiceTemplate.freeswitch.dialPlan[activeTab].steps];
        items[idx].path = filePath;

        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                dialPlan: {
                  ...voiceTemplate.freeswitch.dialPlan,
                  [activeTab]: { steps: items },
                },
              },
            },
            errors: errors,
          })
        );
      },
      [voiceTemplate, activeTab, dispatch, errors]
    );

    const onSetVoiceRetries = useCallback(
      (ev) => {
        dispatch(
          buildMasterTemplate({
            voiceTemplate: {
              ...voiceTemplate,
              freeswitch: {
                ...voiceTemplate.freeswitch,
                retries: ev.target.value,
              },
            },
            errors: omit(errors, `voiceTemplate.freeswitch.retries`),
          })
        );
      },
      [dispatch, voiceTemplate, errors]
    );

    const onSetVoiceAdvance = useCallback(
      (text, errors) => {
        if (!text || text.length < 1) {
          text = null;
        }
        dispatch(
          buildMasterTemplate({
            voiceTemplate: { ...voiceTemplate, advanceVoice: text },
            errors: omit(errors, `voiceTemplate.advanceVoice`),
          })
        );
      },
      [voiceTemplate, dispatch]
    );

    const onRejectVoiceFile = useCallback(
      (idx) =>
        dispatch(
          buildMasterTemplate({
            errors: {
              ...errors,
              [`voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.path`]: t(
                "Upload a valid mp3, wav, m4a or ogg file"
              ),
            },
          })
        ),
      [dispatch, activeTab, errors, t]
    );

    const onUploadVoiceFile = useCallback(
      async ([file], idx) => {
        try {
          const newItems = [
            ...voiceTemplate.freeswitch.dialPlan[activeTab].steps,
          ];

          const path = await dispatch(uploadWavFile(file));
          newItems[idx] = {
            ...newItems[idx],
            path,
          };

          dispatch(
            buildMasterTemplate({
              voiceTemplate: {
                ...voiceTemplate,
                freeswitch: {
                  ...voiceTemplate.freeswitch,
                  dialPlan: {
                    ...voiceTemplate.freeswitch.dialPlan,
                    [activeTab]: { steps: newItems },
                  },
                },
              },
              errors: omit(
                errors,
                `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.path`
              ),
            })
          );

          await onSaveAsDraftInitial(false);
        } catch (err) {
          console.error(err);
          onRejectVoiceFile(idx);
        }
      },
      [
        voiceTemplate,
        activeTab,
        onSaveAsDraftInitial,
        dispatch,
        errors,
        onRejectVoiceFile,
      ]
    );

    const onTabChange = useCallback(
      (_, activeTab) => {
        if (activeTab === LIVE_TYPE) {
          setCopyLive(false);
        }
        setActiveTab(activeTab);
      },
      [setActiveTab]
    );

    const onInputTabChange = useCallback(
      (_, activeTab) => setAdvanceTab(activeTab),
      [setAdvanceTab]
    );

    const {
      vendorConfig: {
        createCustomerTemplateData,
        answeringMachine = false,
        canAddVoiceFile = false,
        canAddDirection = false,
        canAddDTMF = false,
      },
    } = useSelector((state) => state.eonsConfig);

    const canUseCustomerData = createCustomerTemplateData;

    const onToggleCopyLiveToAnsweringMachine = useCallback(
      () => setCopyLive((prevState) => !prevState),
      []
    );

    const removeIncompatibleVoiceFieldsForMachine = (liveStepsCopy) => {
      let steps = [];
      liveStepsCopy.forEach((item) => {
        if (item.action !== "goto") {
          if (item.action == "dtmf") {
            if (item.path && item.path.length) {
              delete item.keys;
              steps.push({ ...item, action: "playback", stepType: "file" });
            }
          } else {
            steps.push(item);
          }
        }
      });

      return {
        ...voiceTemplate.freeswitch.dialPlan[MACHINE_TYPE],
        steps,
      };
    };

    const buildDefaultVoiceTemplate = () => {
      dispatch(
        buildMasterTemplate({
          voiceTemplate: {
            ...voiceTemplate,
            freeswitch: {
              ...voiceTemplate.freeswitch,
              dialPlan: {
                ...voiceTemplate.freeswitch.dialPlan,
                [MACHINE_TYPE]: copyLive
                  ? removeIncompatibleVoiceFieldsForMachine(
                      _.cloneDeep(
                        voiceTemplate.freeswitch.dialPlan[LIVE_TYPE].steps
                      )
                    )
                  : voiceTemplate.freeswitch.dialPlan[MACHINE_TYPE],
              },
            },
          },
        })
      );
    };
    useImperativeHandle(ref, () => ({
      buildDefaultVoiceTemplate,
    }));
    useEffect(() => {
      if (isFirstRender.current) {
        // Skip the effect on the first render
        isFirstRender.current = false;
        return;
      }
      buildDefaultVoiceTemplate();
    }, [copyLive, setActiveTab]);

    return (
      <Grid container spacing={2} data-test-id="master-template-voice-section">
        {/* <Grid item xs={12} sm={12} md={12}>
        <VoiceInputToggle
          onTabChange={onInputTabChange}
          activeTab={advanceTab}
          errors={errors}
          readOnly={readOnly}
        />
      </Grid> */}
        <Grid item xs={12} sm={12} md={12}>
          {advanceTab === SIMPLE_VOICE_INPUT && answeringMachine ? (
            <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 10 }}>
              <VoiceTypeToggle
                answeringMachine={
                  voiceTemplate.freeswitch.dialPlan[MACHINE_TYPE]
                }
                onToggleAnsweringMachine={onToggleAnsweringMachine}
                onTabChange={onTabChange}
                activeTab={activeTab}
                errors={errors}
                readOnly={readOnly}
                copyLiveToAnseringMachine={copyLive}
                onToggleCopyLiveToAnsweringMachine={
                  onToggleCopyLiveToAnsweringMachine
                }
              />
            </Grid>
          ) : (
            ""
          )}
          <Grid spacing={4}>
            {advanceTab === SIMPLE_VOICE_INPUT ? (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <VoiceFields
                    voiceTemplate={voiceTemplate}
                    errors={errors}
                    onVoiceFieldChange={onVoiceFieldChange}
                    onVoiceRetryChange={onSetVoiceRetries}
                    readOnly={readOnly}
                    eonsConfig={eonsConfig}
                    callerIdDetail={callerIdDetail}
                    isEditingView={isEditingView}
                    adhoc={adhoc}
                    onSaveAsDraftInitial={onSaveAsDraftInitial}
                    voiceActionStep={
                      <div data-test-id="master-template-test-voice-action-field">
                        <VoiceStepButtons
                          readOnly={readOnly}
                          data-test-id="master-template-test-voice-action-field"
                          label={t("Add Voice Action Items")}
                          steps={[
                            {
                              testId: `master-template-add-tts-button-${activeTab}`,
                              startIcon: <Add />,
                              onClick: onAddTTSData,
                              label: t("Add TTS"),
                              permission: true,
                            },
                            {
                              testId: `master-template-add-voice-file-button-${activeTab}`,
                              startIcon: <AttachFile />,
                              onClick: onAddVoiceFile,
                              error: errors[`voiceTemplate.${activeTab}`],
                              label: t("Add Voice File"),
                              permission: true && canAddVoiceFile,
                            },
                            {
                              testId: `master-template-add-voice-data-button-${activeTab}`,
                              startIcon: <Add />,
                              onClick: onAddCustomerData,
                              label: t("Add Data Field"),
                              permission: canUseCustomerData,
                            },
                            {
                              testId: `master-template-add-voice-goto-button-${activeTab}`,
                              startIcon: <Directions />,
                              onClick: onAddDirectionData,
                              label: t("Add Direction"),
                              permission:
                                activeTab === LIVE_TYPE && canAddDirection, // disabled GOTO from Machine Tab
                            },
                            {
                              testId: `master-template-add-voice-dtmf-button-${activeTab}`,
                              startIcon: <Dialpad />,
                              onClick: onAddDTMFData,
                              label: t("Add DTMF"),
                              permission: activeTab === LIVE_TYPE && canAddDTMF, // disabled DTMF from Machine Tab
                            },
                          ]}
                        />
                      </div>
                    }
                    voiceActionItems={
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid container direction="column" spacing={2}>
                          {Boolean(voiceItems?.length) && (
                            <Grid item>
                              <VoiceItems
                                voiceItems={voiceItems}
                                selectedTtsVoice={voiceTemplate.ttsVoiceType}
                                errors={errors}
                                readOnly={readOnly}
                                onDeleteVoiceItem={onDeleteVoiceItem}
                                onUploadVoiceFile={onUploadVoiceFile}
                                onRejectVoiceFile={onRejectVoiceFile}
                                onSetCustomerData={onSetCustomerData}
                                setVoiceCustomerData={setVoiceCustomerData}
                                onSetTTSData={onSetTTSData}
                                onSetVoiceItems={onSetVoiceItems}
                                onSetDTMFData={onSetDTMFData}
                                onDTMFFilePathChange={onDTMFFilePathChange}
                                onSetDirectionData={onSetDirectionData}
                                onSaveAsDraftInitial={onSaveAsDraftInitial}
                                activeTab={activeTab}
                                testSection="voice-section"
                              />
                            </Grid>
                          )}
                          {errors[
                            `voiceTemplate.freeswitch.dialPlan.person.steps`
                          ] && (
                            <Alert severity="error">
                              {
                                // errors[
                                //   `voiceTemplate.freeswitch.dialPlan.person.steps`
                                // ]
                                'Use "Add Voice Action Items" button to create your voice template.'
                              }
                            </Alert>
                          )}

                          {errors[`voiceTemplate.${activeTab}`] && (
                            <Grid item className={classes.padded}>
                              <Alert severity="error" variant="filled">
                                {t("At least one voice file is required")}
                              </Alert>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                {/* Deprecated */}
                {/* {activeTab === LIVE_TYPE && (
                <Grid item xs={12} sm={12} md={12}>
                  <VoiceFields
                    voiceTemplate={voiceTemplate}
                    errors={errors}
                    onVoiceFieldChange={onVoiceFieldChange}
                    onVoiceRetryChange={onSetVoiceRetries}
                    readOnly={readOnly}
                    eonsConfig={eonsConfig}
                    isEditingView={isEditingView}
                    voiceActionStep={
                      <VoiceStepButtons
                        label={t("Add Voice Actions")}
                        steps={[
                          {
                            testId: "master-template-add-voice-file-button",
                            startIcon: <AttachFile />,
                            onClick: onAddVoiceFile,
                            error: errors[`voiceTemplate.${activeTab}`],
                            label: t("Add Voice File"),
                            permission: true,
                          },
                          {
                            testId: "master-template-add-voice-data-button",
                            startIcon: <Add />,
                            onClick: onAddCustomerData,
                            label: t("Add Data Field"),
                            permission: canUseCustomerData,
                          },
                          {
                            testId: "master-template-add-voice-dtmf-button",
                            startIcon: <Dialpad />,
                            onClick: onAddDTMFData,
                            label: t("Add DTMF"),
                            permission: activeTab === LIVE_TYPE, // disabled DTMF from Machine Tab
                          },
                        ]}
                      />
                    }
                    voiceActionItems={
                      <Grid item xs={12} sm={12} md={12}>
                        {adhoc ? (
                          <VoiceTTS
                            voiceTemplate={voiceTemplate[activeTab]}
                            onSetVoiceItems={onSetVoiceItems}
                            errors={errors}
                            readOnly={readOnly}
                          />
                        ) : (
                          <Grid container direction="column" spacing={2}>
                            {Boolean(voiceItems?.length) && (
                              <Grid item>
                                <VoiceItems
                                  voiceItems={voiceItems}
                                  errors={errors}
                                  readOnly={readOnly}
                                  onDeleteVoiceItem={onDeleteVoiceItem}
                                  onUploadVoiceFile={onUploadVoiceFile}
                                  onRejectVoiceFile={onRejectVoiceFile}
                                  onSetCustomerData={onSetCustomerData}
                                  onSetVoiceItems={onSetVoiceItems}
                                  onSetDTMFData={onSetDTMFData}
                                  activeTab={activeTab}
                                />
                              </Grid>
                            )}
                            {errors[`voiceTemplate.${activeTab}`] && (
                              <Grid item className={classes.padded}>
                                <Alert severity="error" variant="filled">
                                  {t("At least one voice file is required")}
                                </Alert>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    }
                  />
                </Grid>
              )} */}
              </>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <VoiceAdvanceField
                  advanceVoice={advanceVoice}
                  errors={errors}
                  readOnly={readOnly}
                  onSetVoiceAdvance={onSetVoiceAdvance}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {templateId && (
          <Grid item className={classes.flexDivider}>
            <Tooltip title={templateId} placement="right" interactive>
              <InfoIcon color="disabled" style={{ height: "auto" }} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default memo(VoiceTemplateSection);
