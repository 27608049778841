import React, { useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "../../../components";
import { makeStyles } from "@material-ui/core/styles";
import {
  Visibility,
  Edit,
  DeleteForever,
  Schedule as ScheduleIcon,
  Close as CloseIcon,
  Check,
  CloudDownload as CloudDownloadIcon,
  Sync as SyncIcon,
  HourglassFull
} from "../../../icons";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import LayersClear from "@material-ui/icons/LayersClear";
import FileCopy from "@material-ui/icons/FileCopy";
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import { useTranslation } from "react-i18next";
import useAuthorized, {
  RECIPIENTLIST_DOWNLOAD_PERMISSION,
  RECIPIENTLIST_UPDATE_PERMISSION,
  RECIPIENTLIST_DELETE_PERMISSION,
  REPORT_DOWNLOAD_PERMISSION,
  RECIPIENTLIST_LIST_PERMISSION,
} from "../../../hooks/useAuthorized";

const useStyles = makeStyles((theme) => ({
  done: {
    color: theme.palette.success.main,
  },
}));

function RecipientListsActions({
  recipientList,
  onDelete,
  onClone,
  onDipping,
  onDownload,
  onScheduleDownload,
  onOpenDedupeDialog,
}) {
  const { t } = useTranslation();
  const { _id, userId, status, permission } = recipientList;

  const isTemplateLocked = status === "LOCKED";
  const isDeduping = status === "DEDUPLICATING";

  const {
    vendorConfig: { canEditRecipientList = false },
  } = useSelector((state) => state.eonsConfig);

  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );
  const permissions = useSelector((state) => state.user.permissions);
  const permissionArray = permissions[activeDivisionId] || permissions[null];
  const canDownloadRecipientlist = permissionArray?.includes(
    RECIPIENTLIST_DOWNLOAD_PERMISSION
  );

  const canDownloadList = canDownloadRecipientlist;

  const userCanEditList = permissionArray?.includes(
    RECIPIENTLIST_UPDATE_PERMISSION
  );

  const userCanDeleteList = permissionArray?.includes(
    RECIPIENTLIST_DELETE_PERMISSION
  );

  const canEdit =
    useSelector((state) => state.user.id === userId) &&
    !isTemplateLocked &&
    canEditRecipientList &&
    userCanEditList;

  const hasViewPermission = useAuthorized(RECIPIENTLIST_LIST_PERMISSION);
  const isCompanyOrDivision =
    permission === "company" || permission === "division";

  const isTemplateAuthor = useSelector((state) => state.user.id === userId);

  const canViewListButton =
    isTemplateLocked &&
    (isTemplateAuthor || (hasViewPermission && isCompanyOrDivision));

  const showDeleteIcon = useSelector((state) => state.user.id === userId);

  const allowDipping =
    useSelector((state) => state.eonsConfig.vendorConfig.allowDipping) &&
    !isTemplateLocked;

  const deletable = !isTemplateLocked && showDeleteIcon && userCanDeleteList;

  const canDeDupe = !isTemplateLocked || !isDeduping;
  
  const isCloning = recipientList.status === 'CLONING';

  const _onDelete = useCallback(() => onDelete(recipientList), [
    onDelete,
    recipientList,
  ]);
  const _onClone = useCallback(() => onClone(recipientList), [
    onClone,
    recipientList,
  ]);
  const _onDeduplication = useCallback(
    () => onOpenDedupeDialog(recipientList),
    [onOpenDedupeDialog]
  );

  // if list is deduping, short circuit all actions and return an hourglass indicating the list is being updated
  if (isDeduping) return (
    <>
      <Tooltip title={t("This list is currently being updated")}>
        <IconButton
          size="small"
          disabled
          data-test-id={`recipient-action-disabled-dedupe-${_id}`}
        >
          <HourglassFull fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );

  return (
    <>
      {isCloning ?
        <Tooltip title={t("Preparing recipient list")}>
          <IconButton
            size="small"
            color="primary"
            data-test-id={`recipient-action-clone-${_id}`}
          >
            <HourglassFullIcon fontSize="small" />
          </IconButton>
        </Tooltip> :
        <>
          {allowDipping && (
            <DippingActionButton
              recipientList={recipientList}
              onDipping={onDipping}
            />
          )}
          {canEdit && (
            <Tooltip title={t("Edit recipient list")}>
              <IconButton
                component={Link}
                to={`/recipient-lists/${_id}`}
                size="small"
                data-test-id={`recipient-action-edit-${_id}`}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {canViewListButton && (
            <Tooltip title={t("View List")}>
              <IconButton
                component={Link}
                to={`/recipient-lists/${_id}`}
                size="small"
                data-test-id={`recipient-action-edit-${_id}`}
              >
                <Visibility fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {deletable && (
            <Tooltip title={t("Delete recipient list")}>
              <IconButton
                size="small"
                color="secondary"
                onClick={_onDelete}
                data-test-id={`recipient-action-delete-${_id}`}
              >
                <DeleteForever fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t("Clone recipient list")}>
            <IconButton
              size="small"
              color="primary"
              onClick={_onClone}
              data-test-id={`recipient-action-delete-${_id}`}
            >
              <FileCopy fontSize="small" />
            </IconButton>
          </Tooltip>
          {canDownloadList && (
            <DownloadActionButton
              recipientList={recipientList}
              onDownload={onDownload}
              onScheduleDownload={onScheduleDownload}
            />
          )}

          {canDeDupe && (
            <Tooltip title={t("Deduplication")}>
              <IconButton
                size="small"
                color="primary"
                onClick={_onDeduplication}
                data-test-id={`recipient-list-action-deduplication-${_id}`}
              >
                <LayersClear fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>}
    </>
  );
}

function DippingActions({ recipientList, onDipping }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const _onDipping = useCallback(() => onDipping(recipientList), [
    onDipping,
    recipientList,
  ]);
  const { dip, _id } = recipientList;
  const { status } = dip ? dip : {};

  if (!status)
    return (
      <Tooltip title={t("Data Validation")}>
        <IconButton
          size="small"
          onClick={_onDipping}
          disabled={
            recipientList.dip
              ? recipientList.dip.status
                ? true
                : false
              : false
          }
          data-test-id={`recipient-list-action-dipping-${_id}`}
        >
          <PermPhoneMsgIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );

  if (status === "PENDING" || status === "RUNNING" || status === "SCHEDULED") {
    return (
      <Tooltip
        title={t(
          `Validation in progress`
          // status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
        )}
      >
        <IconButton
          size="small"
          disableRipple={true}
          style={{ cursor: "wait" }}
        >
          <ScheduleIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    );
  }
  if (status === "FAILED")
    return (
      <Tooltip
        title={t(
          status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
        )}
      >
        <IconButton
          size="small"
          disableRipple={true}
          style={{ cursor: "default" }}
        >
          <CloseIcon fontSize="small" color="secondary" />
        </IconButton>
      </Tooltip>
    );

  return (
    <Tooltip title={t("Validation Completed")}>
      <IconButton
        size="small"
        disableRipple={true}
        style={{ cursor: "default" }}
      >
        <Check className={classes.done} size="small" />
      </IconButton>
    </Tooltip>
  );
}

function DownloadActions({ recipientList, onScheduleDownload, onDownload }) {
  const { t } = useTranslation();
  const _onScheduleDownload = useCallback(
    () => onScheduleDownload(recipientList),
    [onScheduleDownload, recipientList]
  );
  const _onDownload = useCallback(() => onDownload(recipientList), [
    onDownload,
    recipientList,
  ]);
  const { scheduleListDownload, _id, dip } = recipientList;
  const { status } = dip ? dip : {}; //Also checking dip status
  const dip_vs_download_sync =
    status === "PENDING" || status === "RUNNING" || status === "SCHEDULED";
  if (
    !scheduleListDownload ||
    scheduleListDownload === "PENDING" ||
    dip_vs_download_sync
  ) {
    return (
      <Tooltip title={t("Download recipient list")}>
        <IconButton
          size="small"
          color="secondary"
          onClick={_onScheduleDownload}
          data-test-id={`recipient-list-action-download-${_id}`}
        >
          <CloudDownloadIcon size="small"></CloudDownloadIcon>
        </IconButton>
      </Tooltip>
    );
  } else if (scheduleListDownload === "SCHEDULED") {
    return (
      <Tooltip title={t("File is getting generated")}>
        <IconButton
          size="small"
          disableRipple={true}
          style={{ cursor: "wait" }}
        >
          <ScheduleIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <>
      <Tooltip title={t("Ready for download")}>
        <IconButton size="small" color="primary" onClick={_onDownload}>
          <CloudDownloadIcon size="small"></CloudDownloadIcon>
        </IconButton>
      </Tooltip>

      <Tooltip title={t("Resync Dipping List")}>
        <IconButton
          size="small"
          disableRipple={true}
          onClick={_onScheduleDownload}
        >
          <SyncIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export const DownloadActionButton = memo(DownloadActions);
export const DippingActionButton = memo(DippingActions);

export default memo(RecipientListsActions);
