import React, { useCallback, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, DropZone, Typography, Tooltip } from "../../../components";
import { Info as InfoIcon } from "../../../icons";
import {
  buildMasterTemplate,
  uploadWavFile,
} from "../../../actions/masterTemplate";
import TTYPreview from "../../Campaigns/Single/TTYPreview";
import _ from "lodash";

const useStyles = makeStyles({
  dropZone: {
    height: 160,
  },
  flexDivider: {
    marginLeft: "auto",
  },
});

function TTYTemplateSection({
  ttyTemplate = {},
  errors = {},
  hasTTYErrors,
  voiceConfig,
  readOnly,
  onSaveAsDraftInitial,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tty = {}, _id: templateId } = ttyTemplate;
  const [ttyFilePath, setTtyFilePath] = useState("");

  const getFileName = (path) => {
    return path ? path : null;
  };

  const onRejectVoiceFile = useCallback(
    () =>
      dispatch(
        buildMasterTemplate({
          errors: {
            ...errors,
            "ttyTemplate.tty": t("Upload a valid wav file"),
          },
        })
      ),
    [dispatch, errors, t]
  );

  const onUploadVoiceFile = useCallback(
    async ([file]) => {
      try {
        await onSaveAsDraftInitial(false);
        const path = await dispatch(uploadWavFile(file));
        setTtyFilePath(path);
        dispatch(
          buildMasterTemplate({
            ttyTemplate: {
              freeswitch: {
                dialPlan: {
                  person: {
                    steps: [
                      {
                        action: "playback",
                        stepType: "file",
                        name: "0",
                        path,
                      },
                    ],
                  },
                },
              },
            },
            errors: omit(
              errors,
              "ttyTemplate",
              "ttyTemplate.tty",
              "ttyTemplate.tty.transcript"
            ),
          })
        );
      } catch (err) {
        console.error(err);
        onRejectVoiceFile();
      }
    },
    [dispatch, voiceConfig, errors, onRejectVoiceFile]
  );

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      data-test-id="master-template-tty-section"
    >
      <Grid item>
        <Typography>
          {t(
            "Teletype is a message format for people who are deaf, hard of hearing, or speech-impaired."
          )}
        </Typography>
      </Grid>
      <Grid item>
        <DropZone
          dataTestId="master-template-tty-file-selector"
          accept=".mp3,.wav,.m4a,.ogg"
          color={readOnly ? null : hasTTYErrors ? "error" : "primary"}
          multiple={false}
          label={
            hasTTYErrors
              ? t("Upload a valid mp3, wav, m4a or ogg file")
              : parseFileName(
                getFileName(
                  ttyTemplate.freeswitch?.dialPlan?.person?.steps[0]?.path
                )
              ) || t("Upload an audio file by clicking or drag-and-drop")
          }
          className={classes.dropZone}
          variant="outlined"
          onDropAccepted={(file) => {
            onUploadVoiceFile(file);
          }}
          onDropRejected={onRejectVoiceFile}
          disabled={readOnly}
        />
      </Grid>
      {!_.isEmpty(ttyFilePath) && (
        <Grid item>
          <TTYPreview tty={ttyFilePath} />
        </Grid>
      )}
      {templateId && (
        <Grid item className={classes.flexDivider}>
          <Tooltip title={templateId} placement="right" interactive>
            <InfoIcon color="disabled" style={{ height: "auto" }} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

function parseFileName(path) {
  if (!path) return false;

  const arr = path.split("/");
  return arr[arr.length - 1];
}

export default memo(TTYTemplateSection);
